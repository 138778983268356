<template>
  <div id="resume">
    <footer class="footer text-center pt-2 pb-5">
        <small class="copyright">Designed with
          <i class="fas fa-heart"></i> by
          <a href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying Riley</a> for developers
        </small>
    </footer>
  </div>
</template>

<script>

  export default {
    name: 'ResumeFooter',
  }
</script>