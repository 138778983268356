<template>
  <Theme />
</template>

<script>
  import Theme from './Theme';

  export default {
    name: 'NavBar',

    components: {
      Theme,
    },
  }
</script>